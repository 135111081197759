<template>
  <lf-input
    class="h-10 flex font-medium z-1"
    :class="{
      'pl-4': !noPadding
    }"
    :placeholder="placeholder"
    :value="inputValue"
    :name="name"
    type="text"
    autocomplete="off"
    :clear-button-enabled="clearButtonEnabled"
    :dark-theme="darkTheme"
    :no-margin="noMargin"
    :disabled="disabled"
    data-cy="search-input-wrapper"
    @keyup="emitValueChange"
    @clear-input="clearField"
  >
    <template v-slot:icon>
      <icon-base width="19" height="18" icon="search" />
    </template>
  </lf-input>
</template>
<script setup lang="ts">
import { toRef, computed } from "vue";
import debounce from "lodash/debounce";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ""
  },
  searchTerm: {
    type: String,
    default: ""
  },
  clearButtonEnabled: {
    type: Boolean,
    default: true
  },
  darkTheme: {
    type: Boolean,
    default: false
  },
  noPadding: {
    type: Boolean,
    default: false
  },
  delay: {
    type: Number,
    default: 600
  },
  noMargin: {
    type: Boolean
  },
  name: {
    type: String,
    default: "search"
  },
  trimValue: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits<{
  (eventName: "update:modelValue", value: string): void;
}>();

const inputValue = toRef(props, "modelValue");
const placeholder = computed(() => `Search ${props.searchTerm}`);

const clearField = () => emit("update:modelValue", "");

const emitValueChange = debounce((e: Event) => {
  const { value } = e.target as HTMLInputElement;
  emit("update:modelValue", props.trimValue ? value.trim() : value);
}, props.delay);
</script>
